.App {
  text-align: center;
}

.mapOptions {
  padding: 3px 4px 5px;
  cursor: pointer;
  background-color: rgb(35, 31, 41);
  border-radius: 20px;
  height: 26px;
  width: 26px;
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center center;
}
#root-label {
  width: 600px !important;
}

.MuiInputBase-root
  .MuiInput-root
  .MuiInput-underline
  .MuiInputBase-formControl
  .MuiInput-formControl {
  width: 600px !important;
}

.MuiTextField-root input {
  width: 400px !important;
}

#myJsonForm fieldset {
  width: 100%;
  border: 0 none;
  margin: 0px;
  padding: 0px;
}

#root__title {
  display: none;
}

#myformLabel {
  margin-right: 15px;
}

.form-control {
  width: 250px;
  display: block;
  border: none;
  border-bottom: 1px solid #757575;
}
.form-control:focus {
  outline: none;
}

.field-description {
  margin: 0px 15px 2px 0px;
  padding: 0px 0px 2px 0px;
}

.primaryFocusedBtn {
  background-color: #ffffff !important;
  color: #000000 !important;
  height: 32px !important;
  border-radius: 30px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
}
.primaryFocusedBtn:hover {
  background-color: #e6e6e6 !important;
  color: #000000 !important;
}

.primaryBtn {
  background: #231f29 !important;
  color: white !important;
  border-radius: 30px !important;
  width: 100% !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 20px 0px 5px 0px !important;
  height: 48px !important;
  cursor: pointer !important;
}
.primaryBtn:hover {
  background-color: #4d4d4d !important;
}
.primaryBtnSmll {
  background: #231f29;
  color: white;
  border-radius: 30px !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 20px 0px 5px 0px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}
.primaryBtnSmll:hover {
  background-color: #4d4d4d;
}
.secondaryBtn {
  background: #f2f2f2 !important;
  color: #000000 !important;
  border-radius: 30px !important;
  width: 100% !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 20px 0px 5px 0px !important;
  height: 48px !important;
  cursor: pointer !important;
}
.secondaryBtn:hover {
  background-color: #e6e6e6 !important;
}

.MuiTableCell-stickyHeader {
  background-color: inherit !important;
}
