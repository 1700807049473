.Dropzone {
  height: 162px;
  font-size: 14px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #312e3a;
}

.folderIconHover {
  margin-top: -12px;
  margin-left: 122px;
  z-index: 100000;
  position: absolute;
  opacity: 0;
}
.Dropzone:hover {
  background-color: #312e3a;
  color: #f7f7f7;
}
.Dropzone:hover ~ .folderIconHover {
  opacity: 1;
}

.Highlight {
  height: 162px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: column;
  font-size: 14px;
  background-color: #bcb9ec;
}

.folderIconHover {
  margin-top: -45px;
  margin-left: 122px;
  z-index: 100000;
  position: absolute;
  opacity: 0;
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.fileInput {
  display: none;
}

.supportFile {
  width: 131px;
  height: 20px;
  opacity: 0.5;
  font-size: 12px;
  color: #666666;
}
